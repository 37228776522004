import { Link } from "react-router-dom";
import styles from "./welcome.module.scss";
import commonStyles from "../commonStyles.module.scss";
import languageIconImageUrl from "../../src/assets/languageIcon.jpeg";
import bgImageUrl from "./wbg.png";
import AlertDialog from "../components/UI/alertDialog/AlertDialog";
import { useEffect, useState } from "react";
import { textFor } from "../util/languages";
import { DEFAULT_LANGUAGE, LANGUAGE_KEY } from "../Constants";
import { clearLocalStorage } from "../util/auth";
import { useDispatch } from "react-redux";
import { clearGroomLists } from "../store/reducers/savedGroomListsSlice";
import { clearProfile } from "../store/reducers/userProfileSlice";
import { clearFamilyMembers } from "../store/reducers/familyMembersSlice";
import { clearFriends } from "../store/reducers/friendsSlice";
import { Loader } from "../components/UI/ErrorModal";

function Welcome() {
  const [isLoading, setIsLoading] = useState("1");
  console.log("Rendering Welcome page...");
  const [selectedLanguageOption, setSelectedLanguageOption] = useState("HINDI");
  const dispatch = useDispatch();
  const [showLanguageOptions, setSshowLanguageOptions] = useState("");

  useEffect(() => {
    clearLocalStorage();
    dispatch(clearGroomLists());
    dispatch(clearProfile());
    dispatch(clearFamilyMembers());
    dispatch(clearFriends());
    const currentLanguage = localStorage.getItem(LANGUAGE_KEY);
    switch (currentLanguage) {
      case "HINDI":
        setSelectedLanguageOption("HINDI");
        break;
      case "ENGLISH":
        setSelectedLanguageOption("ENGLISH");
        break;
      default:
        setSelectedLanguageOption(DEFAULT_LANGUAGE);
        localStorage.setItem(LANGUAGE_KEY, DEFAULT_LANGUAGE);
    }
  }, []);
  const handleChange = (event) => {
    setSelectedLanguageOption(event.target.value);
  };
  function getLanguageChooserView() {
    return (
      <>
        <label className={styles.container}>
          English
          <input
            type="radio"
            checked={selectedLanguageOption === "ENGLISH"}
            name="radio"
            value="ENGLISH"
            onChange={handleChange}
          />
          <span className={styles.checkmark}></span>
        </label>
        <br />
        <label className={styles.container}>
          हिन्दी
          <input
            type="radio"
            checked={selectedLanguageOption === "HINDI"}
            name="radio"
            value="HINDI"
            onChange={handleChange}
          />
          <span className={styles.checkmark}></span>
        </label>
      </>
    );
  }
  function saveLanguage(e) {
    console.log(e.target.value);
    localStorage.setItem(LANGUAGE_KEY, selectedLanguageOption);
    hideLanguageOptionDialog(e);
  }
  function hideLanguageOptionDialog(e) {
    setSshowLanguageOptions("");
  }
  return (
    <>
      {isLoading === "1" && <Loader />}
      <div>
        <img
          src={bgImageUrl}
          alt="Background"
          className={styles.bgImage}
          onLoad={() => setIsLoading("")}
          onError={() => setIsLoading("")}
        />

        {showLanguageOptions && (
          <AlertDialog
            onOkClick={saveLanguage}
            onBackdropClicked={hideLanguageOptionDialog}
            title={textFor("chooseLanguage") + ":"}
            getContent={getLanguageChooserView}
          />
        )}
        <div className={styles.languageIconWrapper}>
          <img
            src={languageIconImageUrl}
            alt="Language"
            className={styles.languageIcon}
            onClick={() => {
              setSshowLanguageOptions("1");
            }}
          ></img>
          <div className={styles.languageLabel}>{textFor("language")}</div>
        </div>
        <br />
        <>
          {textFor("welcomeTitleLine1")}, <br />
          {textFor("welcomeTitleLine2")}... <br />
        </>
        <br />
        <Link className={`${styles.links} ${styles.loginButton}`} to="/login">
          {textFor("loginButton")}
        </Link>
        <br />
        <Link
          className={`${styles.links} ${styles.registerButton}`}
          to="/register"
        >
          {textFor("registerButton")}
        </Link>
      </div>
      <div
        className={commonStyles.bottomSection}
        style={{ fontSize: "xx-small" }}
      >
        version:52
      </div>
    </>
  );
}

export default Welcome;
